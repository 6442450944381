<template>
  <div>
    <v-dialog v-model="show" width="900px">
      <v-card color="#f4f6f9">
        <v-card-title> 3rd Party WifiConfig </v-card-title>

        <v-card-text>
          <v-row class="ma-2">
            <v-file-input outlined v-model="wifiImage" class="mr-2" />
            <v-btn color="primary" x-large @click="save">Add</v-btn></v-row
          >
          <v-autocomplete
            v-model="maxImage"
            :items="imageSizes"
            label="Max image size"
          ></v-autocomplete>
          <v-row justify="center" align="center">
            <img
              :src="getBaseUrl + item.image"
              v-for="item in items"
              :key="item.id"
              class="ma-2"
              @mouseover="currentImage = item.id"
              :width="getImageWidth(item.id)"
              @contextmenu="showMenu($event, item.id)"
          /></v-row>
          <v-menu
            v-model="menu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
          >
            <v-list>
              <v-list-item v-for="(item, index) in menuItems" :key="index">
                <v-list-item-title @click="menuHandler(item.title)">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu></v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ThirdPartyWifiConfigDialog",
  props: ["value", "companyId"],
  data: () => ({
    items: [],
    menu: false,
    x: 0,
    y: 0,
    menuItems: [{ title: "Delete" }],
    wifiImage: null,
    currentImage: 0,
    selectedImage: 0,
    imageSizes: [400, 600, 800],
    maxImage: 400,
    normalImage: 350,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getBaseUrl() {
      return this.$http.defaults.baseURL.replace("api/", "Resources/");
    },
  },
  watch: {
    show(val) {
      val || this.close();
    },
    companyId() {
      this.getWifiImagesByCompany();
    },
  },
  methods: {
    menuHandler(title) {
      switch (title) {
        case "Delete":
          this.deleteImage();
        default:
          break;
      }
    },
    showMenu(e, id) {
      this.selectedImage = id;
      console.log(this.selectedImage);
      e.preventDefault();
      this.menu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menu = true;
      });
    },
    deleteImage() {
      let imageId = this.selectedImage;
      this.$http
        .delete("wifiConfig/DeleteThirdPartyWifiImage", {
          params: { Id: imageId },
        })
        .then((response) => {
          if (response.data.success) {
            let index = this.items.findIndex((x) => x.id == imageId);
            this.items.splice(index, 1);
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },
    getWifiImagesByCompany() {
      this.$http
        .get("wifiConfig/GetThirdPartyWifiConfigsByCompany", {
          params: { companyId: this.companyId },
        })
        .then((response) => {
          this.items = response.data;
        });
    },
    close() {
      this.show = false;
    },
    save() {
      var form = new FormData();
      form.append("Image", this.wifiImage);
      form.append("CompanyId", this.companyId);
      this.$http
        .post("WifiConfig/AddThirdPartyWifiImage", form)
        .then((response) => {
          if (response.data.success) {
            this.items.push(response.data.data);
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },
    getImageWidth(id) {
      if (id == this.currentImage) {
        return this.maxImage;
      } else {
        return this.normalImage;
      }
    },
  },
};
</script>