<template>
  <v-navigation-drawer id="default-drawer" app width="300" :mini-variant.sync="mini" mini-variant-width="0">
    <v-dialog v-model="dialog" width="500px">
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="editedItem.name" label="Company name" />
                <v-col cols="12">
                  <v-file-input v-model="editedItem.image" label="Image" />
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">
            Cancel
          </v-btn>
          <v-btn color="success" text @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="wifiDialog" width="600px">
      <v-card>
        <v-card-title> WiFi Config </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="wifiItem.essid" label="SSID" outlined />
                <v-autocomplete v-model="wifiItem.encryption" :items="wifiEncryptions" item-text="text"
                  item-value="value" label="Encryption" outlined />
                <v-text-field v-model="wifiItem.password" label="Key" outlined />
              </v-col>
              <v-col class="align" cols="6">
                <q-r-code v-if="wifiItem.essid"
                  :value="`WIFI:S:${wifiItem.essid};T:${wifiItem.encryption};P:${wifiItem.password};;`" :size="225" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeWifi">
            Cancel
          </v-btn>
          <v-btn color="success" text @click="saveWifi">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="generateQRDialog" width="750px">
      <v-card>
        <v-card-title> Generate-QR </v-card-title>
        <v-card-text>
          <v-btn color="primary" class="my-2" @click="totalQrs.push('QR')">
            New
          </v-btn>
          <v-row>
            <v-col v-for="(qr, index) in totalQrs" :key="index" class="align" cols="4">
              <v-text-field v-model="qrGenerate['Qr' + index]" label="QR" outlined />
              <q-r-code v-if="qrGenerate['Qr' + index]" :value="qrGenerate['Qr' + index]" :size="100" />
              {{ qrGenerate["Qr" + index] }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="generateQRDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <third-party-wifi-config-dialog v-model="wifiImageDialog" :company-id="getCompanyId" />
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeDelete">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <device-config-dialog :dialog="deviceConfigDialog" :company-id="currentObject.id"
      @close="deviceConfigDialog = false" />
    <device-files-dialog :dialog="deviceFilesDialog" @close="deviceFilesDialog = false" />
    <company-licensee-dialog v-model="companyLicenseeDialog" :company="currentObject" @company="onUpdateCompany" />

    <v-treeview v-if="companies && companies.length" ref="treeview" :items="companies" :load-children="fetchChildren"
      item-key="id" color="warning" open-on-click dense transition @update:open="onExpand">
      <template v-slot:prepend="{ item, open }">
        <img v-if="item.image" :src="getBaseUrl + item.image" class="mt-2"
          style="width:60px; height:40px; object-fit: contain; background: #fff; border-radius:10px;padding:5px">
        <v-icon v-else-if="!item.image && !item.file" color="secondary" class="my-2"
          style="width:60px; height:40px; object-fit: contain; background: #fff; border-radius:10px;padding:3px">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else class="my-2">
          {{ files[item.file] }}
        </v-icon>
      </template>
      <template v-slot:label="{ item, open }">
        <draggable class="list-group" tag="div" :list="[item.id]" group="devices" style="margin-left: 5px;"
          @change="dragEvent($event, item.id)">
          <div>
            {{ item.name }}
          </div>
        </draggable>
      </template>
    </v-treeview>
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
      <v-list>
        <v-list-item v-for="(item, index) in menus" :key="index">
          <v-list-item-title @click="menuHandler(item.title)">
            <v-edit-dialog v-if="item.title.includes('Enroll-QR')">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <template v-slot:input>
                <div class="pa-2 text-center">
                  <q-r-code :value="'' + getCompanyCode" :size="150" />
                  {{ getCompanyCode.toString() }}
                </div>
              </template>
            </v-edit-dialog>
            <div v-else>
              {{ item.title }}
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-navigation-drawer>
</template>

<script>
import { sync, get } from 'vuex-pathify'
import draggable from 'vuedraggable'
import QRCode from '../../views/components/QRCode.vue'
import DeviceConfigDialog from '../../views/components/DeviceConfigDialog.vue'
import DeviceFilesDialog from '../../views/components/DeviceFilesDialog.vue'
import ThirdPartyWifiConfigDialog from '../../views/components/ThirdPartyWifiConfigDialog.vue'
import CompanyLicenseeDialog from '../../views/components/CompanyLicenseeDialog.vue'
import store from '@/store'

export default {
  name: 'DefaultDrawer',
  components: {
    draggable,
    QRCode,
    DeviceConfigDialog,
    DeviceFilesDialog,
    ThirdPartyWifiConfigDialog,
    CompanyLicenseeDialog,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    wifiDialog: false,
    wifiImageDialog: false,
    deviceConfigDialog: false,
    deviceFilesDialog: false,
    generateQRDialog: false,
    companyLicenseeDialog: false,
    qrGenerate: {},
    totalQrs: ['QR'],
    showMenu: false,
    x: 0,
    y: 0,
    currentObject: {},
    menus: [
      { title: 'Licensee' },
      { title: 'WiFi-QR' },
      { title: '3rd Party-QR' },
      { title: 'Enroll-QR' },
      { title: 'Generate-QR' },
      { title: 'Scripts' },
      { title: 'Rename' },
      { title: 'Add' },
      { title: 'Delete' },
    ],
    items: [],
    editedIndex: -1,
    wifiEditedIndex: -1,
    wifiItem: {
      essid: '',
      encryption: '',
      password: '',
      companyId: '',
    },
    defaultWifi: {
      essid: '',
      encryption: '',
      password: '',
      companyId: '',
    },
    editedItem: {
      name: '',
      image: null,
    },
    defaultItem: {
      name: '',
      image: null,
    },
    wifiEncryptions: [{ text: 'WPA/WPA2', value: 'WPA' }],
  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    wifiDialog(val) {
      val || this.closeWifi()
    },
    wifiImageDialog(val) {
      val || this.closeWifiImage()
    },
  },

  async created() {
    const companies = await store.dispatch('company/loadCompanies', 0)
    setTimeout(() => {
      if (companies.length === 1) {
        const company = companies[0]
        this.expandItem(company.id)
      }
    }, 2000)
  },

  computed: {
    ...sync('app', ['drawer', 'drawerImage', 'mini']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    getBaseUrl() {
      return this.$http.defaults.baseURL.replace('api/', 'Resources/')
    },
    getCompanyId() {
      return this.currentObject ? this.currentObject.id : 0
    },
    getCompanyCode() {
      return this.currentObject ? this.currentObject.code : ''
    },
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    companies() {
      return store.getters['company/getBaseCompanies'] || []
    },
  },

  methods: {
    dragEvent(evt, companyId) {
      if (evt.hasOwnProperty('added')) {
        this.$http
          .patch('Company/UpdateDeviceCompany', null, {
            params: { DeviceId: evt.added.element, CompanyId: companyId },
          })
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      }
    },
    onClick(item, isExpanded, nodes) {
      if (isExpanded) {
        store.dispatch('company/selectCompany', item.id)
      } else {
        const last = nodes?.[nodes?.length - 1]
        if (last) {
          store.dispatch('company/selectCompany', last.id)
        } else {
          store.dispatch('company/selectCompany', undefined)
        }
      }
    },
    openMenu(e, item) {
      e.preventDefault()

      this.currentObject = item
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    menuHandler(option) {
      switch (option) {
        case 'Licensee':
          this.companyLicenseeDialog = true
          break
        case 'WiFi-QR':
          this.wifiDialog = true
          this.getWifiConfigByCompany(this.currentObject.id)
          break
        case 'Generate-QR':
          this.generateQRDialog = true
          break
        case '3rd Party-QR':
          this.wifiImageDialog = true
          break
        case 'Scripts':
          this.deviceConfigDialog = true
          break
        case 'Add':
          this.dialog = true
          break
        case 'Rename':
          this.editItem(this.currentObject)
          break
        case 'Delete':
          this.deleteItem(this.currentObject)
          break
        case 'Device-Files':
          this.deviceFilesDialog = true
          break
        default:
          break
      }
    },

    async fetchChildren(item) {
      this.openedCompanyId = item.id
      await store.dispatch('company/loadCompanies', item.id)
    },

    getWifiConfigByCompany(id) {
      this.$http
        .get('WifiConfig/GetWifiConfigByCompany', { params: { companyId: id } })
        .then((response) => {
          if (response.data != null && typeof response.data === 'object') {
            this.wifiItem = Object.assign({}, response.data)
          }
        })
    },

    editItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$http
        .delete('Company/Delete', { params: { id: this.editedItem.id } })
        .then((response) => {
          if (response.data.success) {
            // this.items.splice(index, 1);
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          }
        })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeWifi() {
      this.wifiDialog = false
      this.$nextTick(() => {
        this.wifiItem = Object.assign({}, this.defaultWifi)
        this.wifiEditedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const data = new FormData()
        data.append('name', this.editedItem.name)
        data.append('image', this.editedItem.image)
        data.append('id', this.editedItem.id)
        this.$http.put('Company/Update', data).then((response) => {
          if (response.data.success) {
            // Object.assign(this.items[index], response.data.data);
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      } else {
        const data = new FormData()
        data.append('name', this.editedItem.name)
        data.append('image', this.editedItem.image)
        const companyId = this.currentObject.id
        if (companyId > 0) {
          data.append('companyId', companyId)
        }
        this.$http.post('Company/Create', data).then((response) => {
          if (response.data.success) {
            const result = response.data.data
            if (!result.hasOwnProperty('children')) {
              result.children = []
            }
            this.currentObject.children.push(result)
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          }
        })
      }
      this.close()
    },
    saveWifi() {
      this.wifiItem.companyId = this.currentObject.id
      this.$http
        .post('WifiConfig/UpdateWifiConfig', this.wifiItem)
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      this.closeWifi()
    },
    onUpdateCompany(company) {
      this.currentObject.code = company.code
      this.currentObject.maxLicense = company.maxLicense
    },
    onExpand() {
      this.$nextTick(() => {
        const nodes = this.$refs.treeview.nodes

        for (const [key, value] of Object.entries(nodes)) {
          const element = value.vnode?.$el
          if (element && !element.id) {
            element.id = `drawer:company:${key}`

            const rootElement = element.firstElementChild
            if (rootElement) {
              rootElement.addEventListener('contextmenu', (e) => this.openMenu(e, value.item), true)
              rootElement.addEventListener('click', () => setTimeout(() => {
                const item = value.item
                const isExpanded = element?.getAttribute('aria-expanded') === 'true'
                const activeNodes = Object.values(nodes)
                  .filter(v => v?.vnode?.$el?.getAttribute('aria-expanded') === 'true')
                  .map(v => v.item)

                this.onClick(item, isExpanded, activeNodes)
              }, 2000), true)
            }
          }
        }
      })
    },

    expandItem(companyId) {
      const element = document.getElementById(`drawer:company:${companyId}`)
      if (!element) {
        return
      }

      const isExpanded = element.getAttribute('aria-expanded') === 'true'

      if (isExpanded) {
        return
      }

      element?.firstElementChild?.click?.()
    }
  },
}
</script>
