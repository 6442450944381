<template>
  <div>
    <v-dialog v-model="appDialog" width="1250px">
      <v-card>
        <v-card-title> Device Configs </v-card-title>

        <v-card-text>
          <v-container>
            <v-row />
            <v-data-table :headers="headers" :items="items" show-expand item-key="id" class="mt-2"
              @item-expanded="(data) => getFilePaths(data)">
              <template v-slot:top>
                <user-prompt v-model="deleteDialog" @confirm="deleteConfirm" />
              </template>
              <template v-slot:[`body.prepend`]>
                <tr>
                  <td />
                  <td>
                    <v-text-field v-model="editedItem.name" label="Name" hide-details />
                  </td>
                  <td>
                    <v-autocomplete v-model="editedItem.configType" :items="configTypes" item-text="text"
                      item-value="value" label="Type" hide-details />
                  </td>
                  <td>
                    <v-text-field v-model="editedItem.devicePath" placeholder="SoftClient/MyDevices/"
                      label="Device Path" hide-details />
                  </td>
                  <td style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
                    <v-icon class="mr-2" color="success" @click="Save">
                      {{ editedItem.companyId > 0 ? 'mdi-check' : 'mdi-plus' }}
                    </v-icon>
                    <v-icon color="error" @click="clearConfigItem">
                      mdi-close
                    </v-icon>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.configType`]="{ item }">
                {{ configTypes.find(x => x.value === item.configType).text || "" }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="info" class="mr-2" @click="editConfigItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon color="error" @click="deleteDialog.show({ type: 'Config', item })">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="primary pa-1">
                  <v-data-table :headers="filePathHeader" :items="item.filePaths">
                    <template v-slot:[`body.prepend`]>
                      <tr>
                        <td />
                        <td>
                          <v-text-field v-model="filePathItem.name" label="Name" hide-details />
                        </td>
                        <td>
                          <v-file-input v-model="filePathItem.file" label="File" hide-details />
                        </td>

                        <td style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
                          <v-icon class="mr-2" color="success" @click="AddFile(item)">
                            {{ filePathItem.id > 0 ? 'mdi-check' : 'mdi-plus' }}
                          </v-icon>
                          <v-icon color="error" @click="clearFileItem">
                            mdi-close
                          </v-icon>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:[`item.url-qr`]="{ item }">
                      <div style="padding: 5px">
                        <q-r-code :value="$resourceUrl('/' + item.path)" :size="100" />
                      </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon color="info" class="mr-2" @click="editFileItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon color="error" @click="deleteDialog.show({ type: 'File', item })">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <files-library-dialog v-model="filesLibraryDialog" /> -->
  </div>
</template>

<script>
import QRCode from './QRCode.vue'
import FilesLibraryDialog from './custom/FilesLibraryDialog.vue';

export default {
  name: 'DeviceConfigDialog',
  components: { QRCode, FilesLibraryDialog },
  props: ['dialog', 'companyId'],
  data: () => ({
    filesLibraryDialog: null,
    deleteDialog: null,
    appDialog: false,
    headers: [
      { text: 'Name', sortable: false, value: 'name' },
      { text: 'Type', sortable: false, value: 'configType' },
      { text: 'Device Path', sortable: false, value: 'devicePath' },
      { text: 'Actions', sortable: false, align: 'end', value: 'actions' },
    ],
    filePathHeader: [
      { text: 'QR', sortable: false, value: 'url-qr' },
      { text: 'Name', sortable: false, value: 'name' },
      { text: 'File', sortable: false, value: 'path' },
      { text: 'Actions', sortable: false, align: 'end', value: 'actions' },
    ],
    items: [],
    file: null,
    configTypes: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      configType: 0,
      devicePath: '',
      companyId: 0,
    },
    defaultItem: {
      name: '',
      configType: 0,
      devicePath: '',
      companyId: 0,
    },
    filePathItem: {
      name: '',
      file: null,
      companyId: 0,
      configType: 0,
    },
    defaultFilePathItem: {
      name: '',
      file: null,
      companyId: 0,
      configType: 0,
    },
  }),
  watch: {
    dialog(val) {
      this.appDialog = val
    },
    appDialog(val) {
      val || this.close()
    },
    companyId(val) {
      if (val && val != 0) {
        this.getConfigs()
      }
    },
  },
  created() {
    this.$http.get('DeviceConfig/GetDeviceConfigTypes').then((response) => {
      this.configTypes = response.data
    })
  },
  mounted() {
    //this.filesLibraryDialog.show()
  },
  methods: {
    getConfigs() {
      this.$http
        .get('DeviceConfig/GetDeviceConfigsByCompany', {
          params: { companyId: this.companyId },
        })
        .then((response) => {
          this.items = response.data
        })
    },
    getFilePaths(data) {
      if (
        data.value &&
        (!data.item.filePaths || data.item.filePaths.length == 0)
      ) {
        this.$http
          .get('DeviceConfig/GetFilePathsByDeviceConfigs', {
            params: { deviceConfigId: data.item.id },
          })
          .then((response) => {
            if (response.data) {
              data.item.filePaths.push(...response.data)
            }
          })
      }
    },
    editConfigItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    clearConfigItem() {
      this.editedItem = Object({}, this.defaultItem)
    },
    editFileItem(item) {
      this.filePathItem = Object.assign({}, item)
    },
    clearFileItem() {
      this.filePathItem = Object.assign({}, this.defaultFilePathItem)
    },
    AddFile(item) {
      var form = new FormData()
      form.append('name', this.filePathItem.name)
      form.append('configType', item.configType)
      form.append('deviceConfigId', item.id)
      form.append('companyId', item.companyId)
      form.append('file', this.filePathItem.file)

      this.$http
        .post('DeviceConfig/AddFilesToConfig', form)
        .then((response) => {
          if (response.data.success) {
            item.filePaths.push(response.data.data)
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      this.clear()
    },
    Save() {
      if (this.editedItem.companyId > 0) {
        const index = this.editedIndex
        const item = this.editedItem

        this.$http
          .put(`/DeviceConfig/UpdateDeviceConfig?id=${this.editedItem.id}`, this.editedItem)
          .then((response) => {
            if (response.data.success) {
              this.items.splice(index, 1, item)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      } else {
        this.editedItem.companyId = this.companyId
        this.$http
          .post('DeviceConfig/AddDeviceConfigToCompany', this.editedItem)
          .then((response) => {
            if (response.data.success) {
              this.items.push(response.data.data)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      }
      this.clear()
    },
    clear() {
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.filePathItem = Object.assign({}, this.defaultFilePathItem)
    },
    close() {
      this.appDialog = false
      this.clear()
      this.$emit('close')
    },
    deleteConfirm({ type, item }) {
      if (type === 'Config') {
        this.$http.delete(`DeviceConfig/DeleteDeviceConfig?id=${item.id}`)
          .then((response) => {
            if (response.data.success) {
              const index = this.items.indexOf(item)
              this.items.splice(index, 1)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      }
    },
  },
}
</script>
