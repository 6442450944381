var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"1250px"},model:{value:(_vm.appDialog),callback:function ($$v) {_vm.appDialog=$$v},expression:"appDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Device Configs ")]),_c('v-card-text',[_c('v-container',[_c('v-row'),_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.headers,"items":_vm.items,"show-expand":"","item-key":"id"},on:{"item-expanded":function (data) { return _vm.getFilePaths(data); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('user-prompt',{on:{"confirm":_vm.deleteConfirm},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}})]},proxy:true},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td'),_c('td',[_c('v-text-field',{attrs:{"label":"Name","hide-details":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.configTypes,"item-text":"text","item-value":"value","label":"Type","hide-details":""},model:{value:(_vm.editedItem.configType),callback:function ($$v) {_vm.$set(_vm.editedItem, "configType", $$v)},expression:"editedItem.configType"}})],1),_c('td',[_c('v-text-field',{attrs:{"placeholder":"SoftClient/MyDevices/","label":"Device Path","hide-details":""},model:{value:(_vm.editedItem.devicePath),callback:function ($$v) {_vm.$set(_vm.editedItem, "devicePath", $$v)},expression:"editedItem.devicePath"}})],1),_c('td',{staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"flex-end"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":_vm.Save}},[_vm._v(" "+_vm._s(_vm.editedItem.companyId > 0 ? 'mdi-check' : 'mdi-plus')+" ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":_vm.clearConfigItem}},[_vm._v(" mdi-close ")])],1)])]},proxy:true},{key:"item.configType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.configTypes.find(function (x) { return x.value === item.configType; }).text || "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editConfigItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteDialog.show({ type: 'Config', item: item })}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"primary pa-1",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.filePathHeader,"items":item.filePaths},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td'),_c('td',[_c('v-text-field',{attrs:{"label":"Name","hide-details":""},model:{value:(_vm.filePathItem.name),callback:function ($$v) {_vm.$set(_vm.filePathItem, "name", $$v)},expression:"filePathItem.name"}})],1),_c('td',[_c('v-file-input',{attrs:{"label":"File","hide-details":""},model:{value:(_vm.filePathItem.file),callback:function ($$v) {_vm.$set(_vm.filePathItem, "file", $$v)},expression:"filePathItem.file"}})],1),_c('td',{staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"flex-end"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.AddFile(item)}}},[_vm._v(" "+_vm._s(_vm.filePathItem.id > 0 ? 'mdi-check' : 'mdi-plus')+" ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":_vm.clearFileItem}},[_vm._v(" mdi-close ")])],1)])]},proxy:true},{key:"item.url-qr",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"5px"}},[_c('q-r-code',{attrs:{"value":_vm.$resourceUrl('/' + item.path),"size":100}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editFileItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteDialog.show({ type: 'File', item: item })}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }