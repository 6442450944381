<template>
  <div>
    <v-dialog
      v-model="show"
      width="500px"
    >
      <v-card>
        <v-card-title> Licensee </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="mt-3">
              <v-col cols="12">
                <div class="d-flex align-center">
                  <v-text-field
                    v-model="companyCode"
                    outline
                    label="License Code"
                  />

                  <v-icon
                    class="ml-3"
                    color="success"
                    @click="updateCode"
                  >
                    mdi-check
                  </v-icon>
                </div>

                <div class="d-flex align-center">
                  <v-text-field
                    v-model="companyMaxLicense"
                    type="number"
                    label="Maximum License"
                  />

                  <v-icon
                    class="ml-3"
                    color="success"
                    @click="updateMaxLicensee"
                  >
                    mdi-check
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DeviceConfigDialog',
    props: ['value', 'company'],
    data () {
      return {
        companyCode: this.company.code || '',
        companyMaxLicense: this.company.maxLicense || 0,
      }
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
    watch: {
      company: {
        handler (val) {
          this.companyCode = val.code || ''
          this.companyMaxLicense = val.maxLicense || 0
        },
        deep: true,
      },
    },
    methods: {
      updateCode () {
        const data = new FormData()
        data.append('Id', this.company.id)
        data.append('Code', this.companyCode)

        this.$http.put('Company/UpdateCode', data).then((response) => {
          if (response.data.success) {
            this.$emit('company', response.data.data)
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      },
      updateMaxLicensee () {
        const data = new FormData()
        data.append('Id', this.company.id)
        data.append('MaxLicense', this.companyMaxLicense)

        this.$http.put('Company/UpdateMaxLicense', data).then((response) => {
          if (response.data.success) {
            this.$emit('company', response.data.data)
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      },
      close () {
        this.show = false
      },
    },
  }
</script>
